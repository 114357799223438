#app {
    &.modal-open {
        position: fixed;
    }
}

.modal {
    &-close-button {
        -webkit-appearance: none;
        background: transparent;
        border: 0;
        color: #000;
        cursor: pointer;
        height: 20px;
        opacity: 0.2;
        padding: 0;
        position: absolute;
        right: 14px;
        top: 14px;
        width: 20px;
        z-index: 200;

        @include breakpoint(md) {
            right: 18px;
            top: 22px;
        }

        &:hover, &:focus {
            color: #000;
            text-decoration: none;
            cursor: pointer;
            opacity: 0.5;
            outline: 0;
        }
    }

    &-window {
        background-color: #ffffff;
        box-shadow: 3px 3px 40px rgba(0, 0, 0, 0.5);
        margin: 20px auto 20px auto;
        max-width: 60rem;
        padding: 0;
        position: relative;
        height: auto;
        width: 100%;
    }

    &-footer {
        align-items: center;
        border-top: 1px solid #e0e0e0;
        display: flex;
        justify-content: space-between;
        padding: 15px;

        /* Override, for bootstrap clearfix */
        &:before, &:after {
            content: none;
        }
    }

    &-header {
        padding: 11px 15px 10px;
        border-bottom: 1px solid #e0e0e0;
        background-color: #f8f8f8;

        @include breakpoint(md) {
            padding: 15px 20px;
        }
    }

    &-title {
        color: #444;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: .018em;

        @include breakpoint(md) {
            font-size: 24px;
        }
    }

    &-body {
        cursor: default;
        padding: 15px 20px;
        position: relative;
    }

    &-open {
        height: auto;
        overflow: hidden;
        position: fixed;
        width: 100%;
    }

    &-wrapper {
        align-items: flex-start;
        background-color: rgba(0, 0, 0, .65);
        bottom: 0;
        display: flex;
        height: 100vh;
        left: 0;
        overflow-y: auto;
        padding: 10px;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1000;
        -webkit-overflow-scrolling: touch;
    }

    &--wide {
        .modal-window {
            max-width: 900px;
        }
    }

    &--full-height {
        .modal-window {
            height: calc(100% - 50px);
        }

        form {
            height: calc(100% - 132px);
        }

        .modal-body {
            overflow-y: scroll;
            height: 100%;
        }
    }
}

/*
 * Fade grow transition
 */
.fade-grow-enter-active, .fade-grow-leave-active {
    transition: all .3s ease;
}

.fade-grow-enter, .fade-grow-leave-active {
    opacity: 0;
}

.fade-grow-enter {
    transform: scale(1.05);
}

.fade-grow-leave-active {
    transform: scale(1.02);
}
