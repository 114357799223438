$heading-margin-top: 3rem;
$content-padding-left: 2rem;

hr {
    margin: 2em 0;
    border-style: solid;
    border-color: $color-gray-lighter;
    border-bottom: none;
    border-width: 1px;

    &.no-margin {
        margin: 0;
    }
 }

/* Images */

.image {
    & img {
        width: 100%;
    }
}

.content-block {
    .image {
        margin-top: 6px;

        @include breakpoint(xs, true) {
            float: none;
            width: 100% !important;
            margin: 20px 0;
            padding: 0;
            max-width: 100%;
        }
    }

    &__video {
        &-container {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    &__paragraph {
        width: 100%;

        h1 {
            margin: 20px 0 10px 0;

            @include breakpoint(xs) {
                margin: 40px 0 20px 0;
            }
        }

        img {
            @include breakpoint(xs, true) {
                max-width: 100% !important;
                height: auto !important;
            }
        }

        table {
            margin: auto;
        }

        td {
            border-bottom: 1px solid #bbb;
            padding: 8px 16px;
        }

        p {
            img {
                position: relative;
                top: 6px;
            }
        }

        &--column-left {
            @include breakpoint(sm) {
                float: left;
                width: 50%;
                padding-right: 15px;
            }
        }

        &--column-right {
            @include breakpoint(sm) {
                float: left;
                width: 50%;
                padding-left: 15px;
            }
        }
    }

    &__additional-text {
        p {
            color: #60696c;
            font-family: 'museo', Helvetica, Arial, sans-serif;
            font-size: 18px;
            font-weight: 300;
            letter-spacing: .01em;
            line-height: 1.4;
        }
    }
}

.content-block__paragraph--column-left + .content-block__paragraph--column-clear {
    clear: left;
    width: 100%;
}

.content-block__paragraph--column-right + .content-block__paragraph--column-clear {
    clear: left;
    width: 100%;
}

.image__position {
    &--left {
        float: left;
        margin-right: 20px;
        margin-bottom: 1em;
    }

    &--right {
        float: right;
        margin-left: 20px;
        margin-bottom: 1em;
    }

    &--full {
        width: 100% !important;
        margin-top: 1.6em;
        margin-bottom: 1.6em;
    }

    &--center {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1.3em;

        &.image__next--h3 {
            margin-top: $heading-margin-top;
        }
    }

    &--hero {
        width: 100% !important;
        margin: 0;

        img {
            width: 100%;
        }
    }
}

.image__size {
    &--xSmall {
        width: 25%;
        max-width: 150px;
    }

    &--small {
        width: 33.33%;
        max-width: 220px;
    }

    &--medium {
        width: 50%;
        max-width: 320px;
    }

    &--large {
        width: 66.67%;
        max-width: 480px;
    }

    &--xLarge {
        width: 75%;
        max-width: 600;
    }
}

.image__crop {
    overflow: hidden;

    &--small {
        height: 280px;
    }

    &--medium {
        height: 420px;
    }

    @include breakpoint(xs, true) {
        &--medium {
            height: 160px;
        }

        &--small {
            height: 120px;
        }
    }

    img {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}

.image-banner__wrapper {
    overflow: hidden;
    position: relative;
    width: 100vw;

    &--short-banner-fix {
        @include breakpoint(xl) {
            height: 100% !important;
            width: 100% !important;

            img {
                top: auto !important;
                transform: none !important;
            }
        }
    }

    &--small {
        height: 60vw;

        @include breakpoint(xs) {
            height: 30vw;
        }

        @include breakpoint(md) {
            height: 25vw;
        }

        @include breakpoint(lg) {
            height: 23vw;
        }

        @include breakpoint(xl) {
            width: 1600px;
            height: 300px !important;
        }
    }

    &--medium {
        height: 70vw;

        @include breakpoint(xs) {
            height: 40vw;
        }

        @include breakpoint(md) {
            height: 33vw;
        }

        @include breakpoint(lg) {
            height: 31vw;
        }

        @include breakpoint(xl) {
            width: 1600px;
            height: 420px !important;
        }
    }

    &--large {
        height: 80vw;

        @include breakpoint(xs) {
            height: 50vw;
        }

        @include breakpoint(md) {
            height: 40vw;
        }

        @include breakpoint(lg) {
            height: 37.5vw;
        }

        @include breakpoint(xl) {
            width: 1600px;
            height: 550px !important;
        }
    }

    &--xl {
        height: 90vw;

        @include breakpoint(xs) {
            height: 55vw;
        }

        @include breakpoint(md) {
            height: 45vw;
        }

        @include breakpoint(lg) {
            height: 42.5vw;
        }

        @include breakpoint(xl) {
            width: 1600px;
            height: 650px !important;
        }
    }

    &--full {
        width: 100%;

        @include breakpoint(xl) {
            width: 100% !important;
            height: 100% !important;
        }

        img {
            top: auto !important;
            transform: none !important;
        }
    }

    img {
        width: 100%;
        top: 50%;
        position: relative;
        transform: translateY(-50%);

        @include breakpoint(xs, true) {
            width: 150%;
            left: -25%;
        }
    }
}

.image__next--h3 {
    margin-top: $heading-margin-top / 2;
}

.image__full-width {
    width: 100%;

    img {
        width: 100%;
    }

    &.image__next--h3 {
        margin-top: $heading-margin-top;
    }
}

.image__caption {
    font-size: .9em;
    font-weight: 400;
    color: $color-gray;
    padding-top: .7em;
    line-height: 1.2;
}

/** Pull quote */
.pull-quote {
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    margin: 40px 0;
    padding: 20px 50px;

    @include breakpoint(sm, true) {
        margin: 30px 0;
        padding: 10px;
    }

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    p, h1, h2, h3, h4, h5, h6 {
        color: #0888b9;
        font-family: 'museo', Helvetica, Arial, sans-serif;
        font-weight: 300;
        margin: 10px 0;

        a {
            letter-spacing: 0.015em;
        }
    }

    &--large {
        p, h1, h2, h3, h4, h5, h6 {
            font-size: 26px;
            line-height: 1.4;

            @include breakpoint(xs, true) {
                font-size: 22px;
                line-height: 1.3;
            }
        }
    }

    &--medium {
        p, h1, h2, h3, h4, h5, h6 {
            font-size: 21px;
            line-height: 1.4;

            @include breakpoint(xs, true) {
                font-size: 18px;
                line-height: 1.3;
            }
        }
    }

    &--small {
        p, h1, h2, h3, h4, h5, h6 {
            font-size: 18px;
            line-height: 1.4;

            @include breakpoint(xs, true) {
                font-size: 15px;
                line-height: 1.3;
            }
        }
    }

    img {
        margin-bottom: 10px !important;
    }
}

.content-selection,
.list-item__item {
    @include clearfix;
    margin-bottom: 2em;

    p:last-child {
        margin-bottom: 0;
    }

    .image-selection {
        &.image {
            margin-bottom: 0;

            @include breakpoint(xs, true) {
                float: none;
                width: 100% !important;
                margin: 0 0 20px 0;
                padding: 0;
                max-width: 100%;
            }
        }

        &.image__size {
            &--small {
                width: 16.67%;
            }

            &--medium {
                width: 20%;
            }

            &--large {
                width: 33.33%;
            }
        }
    }
}

.list-item {
    &__date {
        margin: 5px 0 15px 0;
        font-size: 13px;
        color: $color-gray-medium;

        @include breakpoint(xs, true) {
            font-size: 11px;
            font-weight: 600;
            margin: 10px 0 10px 0;
        }
    }

    &__heading {
        margin: 2px 0 0;

        &--featured {
            font-family: 'museo', Helvetica, Arial, sans-serif;
            font-size: 34px;
            margin-top: 0;
        }

        @include breakpoint(xs, true) {
            font-size: 20px;

            &--featured {
                font-size: 24px;
            }
        }
    }

    &__item {
        margin-bottom: 2em;
    }
}

.search__container + .content-block > .list-item__item > hr {
    display: none;
}

.content-block__color {
    .content-block__paragraph {
        h1:first-child,
        h2:first-child,
        h3:first-child,
        h4:first-child,
        p {
            margin-bottom: 20px;
            margin-top: 0;

            @include breakpoint(xs, true) {
                margin-bottom: 10px;
            }
        }

        h1:last-child,
        h2:last-child,
        h3:last-child,
        h4:last-child,
        p {
            margin-bottom: 0;
            margin-top: 20px;

            @include breakpoint(xs, true) {
                margin-top: 10px;
            }
        }

        h2 {
            font-weight: 300;
            line-height: 1.2;
        }
    }
}

.bg-blue,
.bg-yellow,
.bg-maroon {
    .content-block__paragraph {
        h1, h2, h3, h4, h5, h6, p {
            color: white !important;
        }
    }
}

.content-block__button {
    &--combine-with-previous {
        margin-top: -15px !important;
        padding-top: 0 !important;

        @include breakpoint(xs, true) {
            .content-block__color-button {
                margin: 0 0 15px 0;
            }
        }
    }
}

.content-block__color-button {
    display: flex;
    align-items: center;

    a.btn.btn-outline {
        margin: 10px 0;

        @include breakpoint(xs, true) {
            flex: 0 1 auto;
            line-height: 1.4;
            margin: 10px 0 0;
            max-width: 80%;
            white-space: normal;
        }
    }

    &--left {
        justify-content: flex-start;
    }

    &--center {
        justify-content: center;
    }

    &--right {
        justify-content: flex-end;
    }
}

.btn {
    cursor: pointer;

    &.btn-outline {
        align-items: center;
        border-radius: 0;
        border: 2px solid $color-gray;
        color: $color-gray;
        display: flex;
        flex: 0 0 auto;
        font-weight: 600;
        justify-content: center;
        letter-spacing: .1em;
        line-height: 1.1;
        margin: 5px 10px;
        text-transform: uppercase;
        transition: all .15s;
        font-size: 13px;
        padding: 12px 20px;

        @include breakpoint(sm) {
            font-size: 15px;
            padding: 14px 40px;
        }

        &:hover, &:focus {
            border-color: $bg-blue;
            color: $bg-blue;
        }

        &--blue {
            border-color: $bg-blue;
            color: $bg-blue;

            &:hover, &:focus {
                border-color: darken($bg-blue, 8%);
                color: darken($bg-blue, 8%);
                background: rgba(255, 255, 255, .3);
            }
        }

        &--yellow {
            border-color: $text-yellow;
            color: $text-yellow;

            &:hover, &:focus {
                border-color: darken($text-yellow, 8%);
                color: darken($text-yellow, 8%);
                background: rgba(255, 255, 255, .3);
            }
        }

        &--maroon {
            border-color: $bg-maroon;
            color: $bg-maroon;

            &:hover, &:focus {
                border-color: darken($bg-maroon, 8%);
                color: darken($bg-maroon, 8%);
                background: rgba(255, 255, 255, .3);
            }
        }

        &--tan {
            border-color: $bg-tan;
            color: $bg-tan;

            &:hover, &:focus {
                border-color: darken($bg-tan, 8%);
                color: darken($bg-tan, 8%);
                background: rgba(255, 255, 255, .3);
            }
        }

        &--tan-light, &--tanLight {
            border-color: $bg-tan-light;
            color: $bg-tan-light;

            &:hover, &:focus {
                border-color: darken($bg-tan-light, 8%);
                color: darken($bg-tan-light, 8%);
                background: rgba(255, 255, 255, .3);
            }
        }

        &--tan-dark, &--tanDark {
            border-color: $bg-tan-dark;
            color: $bg-tan-dark;

            &:hover, &:focus {
                border-color: darken($bg-tan-dark, 8%);
                color: darken($bg-tan-dark, 8%);
                background: rgba(255, 255, 255, .3);
            }
        }

        &--gray {
            border-color: $color-gray;
            color: $color-gray;

            &:hover, &:focus {
                border-color: darken($color-gray, 8%);
                color: darken($color-gray, 8%);
                background: rgba(255, 255, 255, .3);
            }
        }

        &--black {
            border-color: $color-black;
            color: $color-black;

            &:hover, &:focus {
                border-color: black;
                color: black;
                background: rgba(255, 255, 255, .3);
            }
        }

        &--white {
            border-color: white;
            color: white;

            &:hover, &:focus {
                border-color: $bg-yellow;
                color: $bg-yellow;
                background: rgba(255, 255, 255, .3);
            }
        }

        &.btn-outline-reversed--yellow {
            border-color: white;
            color: white;
            opacity: .8;

            &:hover, &:focus {
                background: darken($bg-yellow, 4%);
                opacity: 1;
            }
        }

        &.btn-outline-reversed--blue {
            border-color: white;
            color: white;
            opacity: .65;

            &:hover, &:focus {
                background: darken($bg-blue, 3%);
                opacity: 1;
            }
        }
    }
}

.author {
    font-size: 16px;
    margin: -25px 0 35px 0;
}

.get-involved__intro {
    margin-bottom: -30px;
}

.get-involved__block {
    position: relative;

    .content-block__button {
        &.content-block__button--combine-with-previous {
            padding-bottom: 15px !important;
        }
    }
}

.get-involved__divider {
    border-bottom: 1px solid $color-gray-light;
    display: block;
    margin: 0;
    padding-top: 15px;
    width: 100%;

    &:last-of-type {
        display: none;
    }
}

.get-involved__anchor {
    height: 1px;
    position: absolute;
    top: -160px;
    width: 1px;
}

/**
 * Fixes for About Us section on Safari iOS 10
 */
.container__about-us {
    position: relative !important;
    top: 0 !important;
}

.content-block__about-us {
    position: relative !important;
    top: 0 !important;
}
