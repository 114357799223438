.text-xl {
    font-size: 30px;

    @include breakpoint(xs) {
        font-size: 36px;
    }

    @include breakpoint(sm) {
        font-size: 46px;
    }
}

.text-md {
    font-size: 13px;
}

.text-uppercase {
    text-transform: uppercase;
    letter-spacing: 0.15em;
}

.text-blue,
.text-blue h1, .text-blue h2, .text-blue h3, .text-blue h4, .text-blue h5, .text-blue p {
    color: $text-blue !important;
}

.text-maroon,
.text-maroon h1, .text-maroon h2, .text-maroon h3, .text-maroon h4, .text-maroon h5, .text-maroon p {
    color: $bg-maroon !important;
}

.text-yellow,
.text-yellow h1, .text-yellow h2, .text-yellow h3, .text-yellow h4, .text-yellow h5, .text-yellow p {
    color: $text-yellow !important;
}

.text-gray,
.text-gray h1, .text-gray h2, .text-gray h3, .text-gray h4, .text-gray h5, .text-gray p {
    color: $color-gray !important;
}

.text-white,
.text-white h1, .text-white h2, .text-white h3, .text-white h4, .text-white h5, .text-white p {
    color: white !important;
}

.text-black,
.text-black h1, .text-black h2, .text-black h3, .text-black h4, .text-black h5, .text-black p {
    color: $color-black !important;
}

.text-tan,
.text-tan h1, .text-tan h2, .text-tan h3, .text-tan h4, .text-tan h5, .text-tan p {
    color: $bg-tan !important;
}

.text-tanLight,
.text-tanLight h1, .text-tanLight h2, .text-tanLight h3, .text-tanLight h4, .text-tanLight h5, .text-tanLight p {
    color: $bg-tan-light !important;
}

.text-tan-light,
.text-tan-light h1, .text-tan-light h2, .text-tan-light h3, .text-tan-light h4, .text-tan-light h5, .text-tan-light p {
    color: $bg-tan-light !important;
}

.text-tanDark,
.text-tanDark h1, .text-tanDark h2, .text-tanDark h3, .text-tanDark h4, .text-tanDark h5, .text-tanDark p {
    color: $bg-tan-dark !important;
}

.text-tan-dark,
.text-tan-dark h1, .text-tan-dark h2, .text-tan-dark h3, .text-tan-dark h4, .text-tan-dark h5, .text-tan-dark p {
    color: $bg-tan-dark !important;
}

a.link-gray {
    font-weight: 400;
    color: $color-gray;
    text-decoration: none;
}

a.btn-link {
    font-weight: 400;
    color: $text-blue;
    text-decoration: none !important;

    &:hover {
        color: $text-yellow;
    }

    &--selected {
        color: $color-black;
    }

    @include breakpoint(xs, true) {
        font-size: 16px;
        display: block;
        line-height: 1.6;
    }
}

.btn-link__divider {
    &:after {
        color: $color-gray-light;
        content: '|';
        font-size: 18px;
        font-weight: 300;
        margin: 0 14px;

        @include breakpoint(xs, true) {
            display: none;
        }
    }
}

.link-gray-blue {
    &:hover {
        color: $text-blue;
    }
}

.link-gray-maroon {
    &:hover {
        color: $bg-maroon;
    }
}

.link-gray-yellow {
    &:hover {
        color: $text-yellow;
    }
}
