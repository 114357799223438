.scroll-top {
    background: rgba(255, 255, 255, 0.07) url(/images/site/scroll-top-arrow.svg) no-repeat center 50%;
    border-radius: 50%;
    border: 1px solid $color-gray-light;
    bottom: 40px;
    display: inline-block;
    height: 40px;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    right: 10px;
    text-indent: 100%;
    transition: opacity .3s 0s, visibility 0s .3s;
    visibility: hidden;
    white-space: nowrap;
    width: 40px;

    &.scroll-is-visible {
        transition: opacity .3s 0s, visibility 0s 0s;
        visibility: visible;
        opacity: 1;
    }
}

.no-touch .scroll-top:hover {
    transition: opacity .3s 0s, visibility 0s 0s;
    background-color: #e86256;
    opacity: 1;
}

@media only screen and (max-width: 1023px) {
    .scroll-top {
        background-position-y: 9px;
        background-size: 45%;
        bottom: 15px;
        right: 10px;
    }
}

@media only screen and (min-width: 1024px) {
    .scroll-top {
        background-position-y: 12px;
        background-size: 40%;
        bottom: 32px;
        height: 50px;
        right: 30px;
        width: 50px;
    }
}
