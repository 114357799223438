.top-nav {
    display: flex;
    height: 35px;
    position: relative;
    text-align: center;

    @media (min-width: 850px) {
        height: 45px;
    }

    &__dropdown {
        display: flex;
        flex: 1 1 auto;
        position: relative;
    }

    &__dropdown-bar {
        background: $bg-yellow;
        box-shadow: 2px 2px 6px -1px rgba(0, 0, 0, .3);
        display: flex;
        flex-direction: column;
        left: 0;
        position: absolute;
        right: -136px;
        text-align: left;
        top: 35px;
        z-index: 100;

        @media (min-width: 850px) {
            top: 45px;
        }

        .top-nav__menu, a.top-nav__menu {
            background: $bg-yellow;
            border-bottom: 1px solid lighten($bg-yellow, 10%);
            color: lighten($text-yellow, 55%) !important;
            justify-content: flex-start;
            padding: 15px 18px;
            user-select: none;

            &:last-of-type {
                border-bottom: none;
            }

            &:hover, &:focus {
                background: darken($bg-yellow, 6%);
                color: white;
            }
        }
    }
}

.top-nav__menu, a.top-nav__menu {
    align-items: center;
    color: $color-gray;
    cursor: pointer;
    display: flex;
    flex: 1 1 auto;
    font-size: 13px;
    font-weight: 700;
    justify-content: center;
    letter-spacing: .1em;
    line-height: 1.1;
    padding: 0 18px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .2s;
    user-select: none;

    @media (min-width: 850px) {
        font-size: 15px;
    }

    &--red {
        background: $bg-maroon;
        color: white;

        &:hover, &:focus {
            background: $bg-yellow !important;
        }
    }

    &:hover, &:focus {
        background: $bg-blue;
        color: white;
    }

    &--active {
        background: darken($bg-blue, 8%);
        color: white !important;
    }

    &--selected {
        color: $color-black;

        .top-nav__menu, a.top-nav__menu {
            color: $color-black;
        }
    }
}

/*
 * Vue transition, fade in & out and expand down a bit
 */
.top-nav-enter-active, .top-nav-leave-active {
    transition: all .2s;
    transform: scale(1);
    transform-origin: left top;
}

.top-nav-enter, .top-nav-leave-active {
    opacity: 0;
    transform: scale(.98);
}

.top-nav-mobile {
    &__button {
        align-items: center;
        border: none;
        cursor: pointer;
        display: flex;
        height: 60px;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;

        &:hover {
            .top-nav-mobile__icon {
                fill: #888;
            }
        }
    }

    &__icon {
        width: 32px;
        height: 32px;
        fill: #aaa;
        position: relative;
        transform: rotate(0);
        transition: transform 200ms;

        &.show {
            transform: rotate(90deg);

            .top-nav-mobile__icon--closed {
                opacity: 0;
            }

            .top-nav-mobile__icon--opened {
                opacity: 1;
            }
        }

        .top-nav-mobile__icon--closed,
        .top-nav-mobile__icon--opened {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            transition: opacity .3s;
        }

        .top-nav-mobile__icon--closed {
            opacity: 1;
        }

        .top-nav-mobile__icon--opened {
            opacity: 0;
        }
    }

    &__links {
        p {
            margin: 0 20px;
            padding: 0;
            border-bottom: 1px solid $color-gray-light;
            font-size: 15px;

            a {
                padding: 8px 0;
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }

    &__menu {
        display: flex;
        flex-direction: column;
        width: 250px;
        position: absolute;
        right: 0;
        top: 0;
        box-shadow: -4px 3px 6px -1px rgba(0, 0, 0, 0.25);
        background: $bg-tan;
        transition: transform 150ms ease-in-out;

        &.submenu_open {
            transform: translateX(-260px);
        }
    }

    &__menu-container {
        width: 260px;
        height: calc(100vh - 60px);
        right: 0;
        top: 60px;
        position: absolute;
        overflow: hidden;
    }

    &__menu-item {
        align-items: center;
        background: $bg-blue;
        border-bottom: 1px solid lighten($bg-blue, 7%);
        color: white;
        cursor: pointer;
        display: flex;
        flex: 1 1 auto;
        font-size: 15px;
        font-weight: 700;
        justify-content: flex-start;
        letter-spacing: .1em;
        line-height: 1.2;
        margin: 0;
        padding: 15px 20px;
        text-decoration: none !important;
        text-transform: uppercase;
        transition: all .2s;
        user-select: none;

        &:last-of-type {
            border-bottom: 1px solid transparent;
        }

        &:hover {
            background: $bg-yellow;
            border-bottom: 1px solid $bg-yellow;
            color: white !important;
        }

        &--maroon {
            background: $bg-maroon;
            border-bottom: none;

            &:hover {
                background: $bg-yellow;
                border-bottom: none;
            }
        }

        &--yellow {
            background: $bg-yellow;
            border-bottom: 1px solid lighten($bg-yellow, 20%);

            &:hover {
                background: darken($bg-yellow, 10%);
            }
        }

        &.has-submenu {
            position: relative;

            i {
                font-size: 29px;
                position: absolute;
                top: 9px;
                right: 14px;
                color: white;
                opacity: .5;
            }
        }
    }

    &__social {
        padding: 5px 20px;
        display: flex;
        justify-content: space-around;

        p.icon-social {
            margin: 0;
            padding: 10px;
            font-size: 20px;
        }
    }

    &__submenu {
        display: none;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 100;
        left: 260px;
        box-shadow: -4px 3px 6px -1px rgba(0, 0, 0, 0.25);

        &--back {
            align-items: center;
            background: $bg-tan-dark;
            color: $color-gray-medium;
            cursor: pointer;
            display: flex;
            flex: 1 1 auto;
            font-size: 15px;
            font-weight: 500;
            justify-content: flex-start;
            letter-spacing: .1em;
            line-height: 1.1;
            margin: 0;
            padding: 15px 20px;
            text-decoration: none !important;
            text-transform: uppercase;
            transition: all .2s;
            user-select: none;
            height: 46px;

            i {
                font-size: 29px;
                position: relative;
                top: 1px;
                margin-right: 8px;
                left: -2px;
                color: $text-blue;
            }
        }
    }
}
