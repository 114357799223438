.home {
    &__hero {
        position: relative;

        &-heading {
            color: white;
            font-size: 51px;
            line-height: 1.1;
            margin: 0 0 30px 0;
        }

        &-overlay {
            align-items: flex-end;
            color: white;
            display: flex;
            height: 100%;
            justify-content: center;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .image__position--hero {
            height: 100%;
            width: 100% !important;
            margin: 0;

            img {
                width: 100%;

                @include breakpoint(lg) {
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

.statistics {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 0 1em;
    text-align: center;

    &__column {
        flex: 1 0 0px;
        padding: 0 1em;
    }

    h2 {
        margin: 0;
        font-size: 60px;
        font-weight: 400;
        letter-spacing: -.025em;
    }

    p {
        margin: -4px 0;
        font-size: 18px;
        line-height: 1.25;
    }
}

.image-block-homepage, .image-block-homepage__content {
    overflow: hidden;
    position: relative;
    margin: 0;

    .content-block__paragraph {
        h1:first-child,
        h2:first-child,
        h3:first-child,
        h4:first-child,
        p {
            margin-bottom: 20px;
            margin-top: 0;
        }

        h1:last-child,
        h2:last-child,
        h3:last-child,
        h4:last-child,
        p {
            margin-bottom: 0;
            margin-top: 20px;
        }

        h2 {
            line-height: 1.2;
        }

        @include breakpoint(xs, true) {
            padding: 0 20px !important;

            h1 {
                font-size: 18px;
                margin: 0 0 7px 0 !important;
            }

            h2 {
                font-size: 18px;
                margin: 0 0 7px 0 !important;
            }

            p {
                font-size: 11px;
                line-height: 1.4;
                font-weight: 600;
                margin: 0 0 7px 0 !important;
            }
        }
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;

        &--left {
            justify-content: flex-start;

        }

        &--right {
            justify-content: flex-end;
        }

        .btn {
            margin: 0;
        }

        @include breakpoint(xs, true) {
            padding: 0 20px !important;
            margin-top: 10px;

            .btn {
                padding: 10px 16px;
                font-size: 12px;
            }
        }
    }

    &__content {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 40px 0;

        @include breakpoint(xs, true) {
            font-weight: 600 !important;
            padding: 20px 0;

            h1, h2, p {
                color: white !important;
            }
        }
    }
}

.btn {
    @include breakpoint(xs, true) {
        padding: 10px 16px !important;
        font-size: 12px !important;
    }
}

.hero-carousel {
    height: 40vw;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;

    @include breakpoint(xs, true) {
        height: 60vw;
    }
}

.hero-carousel__buttons {
    bottom: 12px;
    display: flex;
    position: absolute;
    right: 12px;
    z-index: 100;

    @include breakpoint(sm) {
        right: 25px;
        bottom: 25px;
    }
}

.hero-carousel__button {
    background: white;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    height: 10px;
    margin-left: 10px;
    opacity: .3;
    transition: opacity 300ms;
    width: 10px;

    &:hover {
        opacity: 1;
    }

    &--active {
        opacity: 1;
    }
}

.hero-carousel__container {
    background: $bg-tan-dark;
    height: 40vw;
    width: 100%;

    @include breakpoint(xs, true) {
        height: 60vw;
    }
}

.hero-carousel__slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 600ms;

    &--visible {
        opacity: 1;
    }
}

.hero-carousel__image {
    background: $bg-tan-dark;
    height: 40vw;
    overflow: hidden;

    @include breakpoint(xs, true) {
        height: 60vw;
    }

    &--full-height img {
        height: 100%;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
    }

    &--full-width img {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }
}

.hero-carousel__overlay {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    user-select: none;
    width: 100%;
}

.hero-carousel__link {
    cursor: pointer;
}

h1.hero-carousel__heading {
    font-family: 'museo', Helvetica, Arial, sans-serif;
    font-size: 1.8em;
    line-height: 1.1;
    margin: 3px;
    text-align: inherit;

    @include breakpoint(xs) {
        font-size: 3em;
    }

    @include breakpoint(sm) {
        font-size: 4em;
    }
}

h2.hero-carousel__subheading {
    font-family: 'museo', Helvetica, Arial, sans-serif;
    font-size: 1em;
    line-height: 1.3;
    margin: 3px;
    text-align: inherit;
    padding: 0 10px;

    @include breakpoint(xs) {
        font-size: 1.6em;
        padding: 0 60px;
    }

    @include breakpoint(sm) {
        font-size: 2.2em;
    }
}

.hero-carousel__text {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex: 1 1 auto;
    padding: 15px 10px 30px 10px;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, .4);

    @include breakpoint(sm) {
        padding: 35px 60px 50px 60px;
    }

    &--blue {
        color: $bg-blue;
    }

    &--yellow {
        color: $text-yellow;
    }

    &--maroon {
        color: $bg-maroon;
    }

    &--tan {
        color: $bg-tan;
    }

    &--tan-light, &--tanLight {
        color: $bg-tan-light;
    }

    &--tan-dark, &--tanDark {
        color: $bg-tan-dark;
    }

    &--gray {
        color: $color-gray;
        text-shadow: none;
    }

    &--black {
        color: $color-black;
        text-shadow: none;
    }

    &--white {
        color: white;
    }

    &--left {
        text-align: left;
        align-items: flex-start;
    }

    &--center {
        text-align: center;
        align-items: center;
    }

    &--right {
        text-align: right;
        align-items: flex-end;
    }

    &--top {
        justify-content: flex-start;
    }

    &--middle {
        justify-content: center;
    }

    &--bottom {
        justify-content: flex-end;
    }
}

.hero-carousel__more {
    align-items: center;
    bottom: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    left: 50%;
    opacity: .6;
    padding: 7px;
    position: absolute;
    transform: translateX(-50%);
    width: 100px;

    @include breakpoint(sm) {
        padding: 20px;
    }

    &:hover {
        transition: opacity .3s 0s;
        opacity: 1;
    }

    .arrow-button {
        background-size: 62%;
        background: url(/images/site/up-arrow-white.svg) no-repeat center 50%;
        display: block;
        height: 20px;
        transform: rotate(180deg);
        width: 40px;
    }
}

