.nowrap, .no-wrap {
    overflow: hidden;
}

.no-break {
    white-space: nowrap;
}

.no-padding {
    padding: 0 !important;
}

$constrain-width: 33rem;
$constrain-sm-width: 26rem;
$constrain-lg-width: 48rem;
$constrain-xl-width: 60rem;

.constrain {
    margin-right: auto;
    margin-left: auto;
    max-width: $constrain-width;
}

.constrain-sm {
    max-width: $constrain-sm-width;
}

.constrain-lg {
    max-width: $constrain-lg-width;
}

.constrain-xl {
    max-width: $constrain-xl-width;
}

.constrain-fixed,
.full-width {
    width: 100% !important;
}

.full-height {
    height: 100% !important;
}

.down-1 {
    top: 1px;
    position: relative;
}

.down-2 {
    top: 2px;
    position: relative;
}

.down-3 {
    top: 3px;
    position: relative;
}

.flex {
    display: flex;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.relative {
    position: relative;
}
