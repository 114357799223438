$carousel-hover-speed: .2s;

.carousel {
    &__all-slides {
        display: flex;
        background: white;
    }

    &__button {
        cursor: pointer;
        user-select: none;
        position: absolute;
        background: $bg-tan;
        z-index: 10;
        top: 50%;

        width: 40px;
        height: 40px;
        background: white;
        border: 1px solid darken($color-gray-lighter, 10%);
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: all .2s ease-in-out;

        &:hover {
            border-color: $text-blue;

            .icon {
                color: $text-blue;
            }
        }

        .icon {
            font-size: 27px;
            color: darken($color-gray-lighter, 10%);
            transition: color .25s ease-in-out;
        }

        &-next {
            right: 0;
            padding-left: 3px;
            transform: translate(50%, -50%);
        }

        &-previous {
            left: 0;
            padding-right: 3px;
            transform: translate(-50%, -50%);
        }

        &--maroon {
            &:hover {
                border-color: $bg-maroon;

                .icon {
                    color: $bg-maroon;
                }
            }
        }

        &--yellow {
            &:hover {
                border-color: $bg-yellow;

                .icon {
                    color: $text-yellow;
                }
            }
        }

        &--disabled {
            opacity: .45;

            &:hover {
                border-color: $color-gray-lighter;

                .icon {
                    color: $color-gray-lighter;
                }
            }
        }
    }

    &__container {
        margin: 0 -15px;
        overflow: hidden;
        position: relative;
    }

    &__container-outer {
        position: relative;
    }

    &__slide {
        flex: 1;
        margin: 0 15px;
        background: lighten($bg-tan, 3%);
        position: relative;
        transition: all $tile-hover-speed;

        a {
            text-decoration: none;
        }

        &:not(:nth-child(2)) {
            &:after {
                content: '';
                height: 100%;
                background: $color-gray-lighter;
                width: 1px;
                position: absolute;
                top: 0;
                transform: translateX(-15px);
            }
        }

        &:hover {
            .carousel__slide-heading {
                color: white;
            }

            .carousel__slide-image {
                img {
                    opacity: 1;
                }
            }
        }

        &-image {
            background: lighten($bg-tan, 3%);
            height: 140px;
            overflow: hidden;
            width: 100%;

            img {
                display: block;
                min-height: 102%;
                min-width: 102%;
                opacity: .85;
                position: relative;
                top: 50%;
                transform: translate(-1%,-51%);
                width: 100%;
            }
        }

        &-heading {
            font-family: 'museo', Helvetica, Arial, sans-serif;
            font-size: 17px;
            font-weight: 400;
            letter-spacing: .01em;
            line-height: 1.25;
            margin: 0 0 15px;
        }

        &-overlay {
            background: transparent;
            box-shadow: inset 0 4px 20px -5px rgba(0, 0, 0, .15);
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 100;
            pointer-events: none;
        }

        &-text {
            padding: 14px 20px;
            height: 115px;
        }

        &-border-top {
            background: transparent;
            height: 6px;
            position: absolute;
            top: 0;
            transition: background $tile-hover-speed;
            width: 100%;
            z-index: 10;
        }

        &--faded {
            .carousel__slide-image {
                img {
                    opacity: .2;
                }
            }
        }

        &--yellow {
            .carousel__slide-heading {
                color: $text-yellow;
            }

            &:hover {
                background: $bg-yellow;

                .carousel__slide-border-top {
                    background: $bg-yellow;
                }
            }
        }

        &--blue {
            .carousel__slide-heading {
                color: $bg-blue;
            }

            &:hover {
                background: $bg-blue;

                .carousel__slide-border-top {
                    background: $bg-blue;
                }
            }
        }

        &--maroon {
            .carousel__slide-heading {
                color: $bg-maroon;
            }

            &:hover {
                background: $bg-maroon;

                .carousel__slide-border-top {
                    background: $bg-maroon;
                }
            }
        }
    }
}
