.form__container {
    padding: 0 40px;

    @include breakpoint(sm) {
        padding: 0 60px;
    }

    @include breakpoint(md) {
        padding: 0 120px;
    }

    @include breakpoint(lg) {
        padding: 0 200px;
    }

    .pull-quote {
        padding: 20px 0;
        p {
            color: $color-gray-medium;
            line-height: 1.5;
        }
    }
}

.form__salsa {
    margin: auto;
    max-width: 760px;
    padding-bottom: 0 !important;
    padding-top: 0 !important;

    .sli-columns {
        padding: 0 10px !important;

        &:first-of-type {
            padding-left: 0 !important;
        }

        &:last-of-type {
            padding-right: 0 !important;
        }
    }

    @media only screen and (max-width: 640px) {
        .sli-columns {
            padding: 0 !important;
        }
    }

    .sli-text {
        p {
            color: #22292a;
            font-family: "Muli", 'museo-sans', Helvetica, Arial, sans-serif !important;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: -0.005em;
            line-height: 1.55;
            margin-bottom: 25px;
        }
    }

    .sli-select-radio {
        label {
            font-size: 15px !important;

            input {
                margin-right: 5px;
                bottom: 2px;
                position: relative;
            }
        }
    }

    .sli-row {
        margin: 0 !important;
    }

    .sli-form, .sli-confirmation-pane, .sli-element {
        color: $text-body-dark !important;
        -webkit-font-smoothing: antialiased !important;
        font-family: 'museo-sans', Helvetica, Arial, sans-serif !important;
        font-weight: 400 !important;
        font-size: 16px !important;

        h4 {
            margin-bottom: 5px !important;
            margin-top: 15px !important;
        }

        h3, ul {
            width: 100%;
            margin-left: auto !important;
            margin-right: auto !important;
            margin-bottom: 0 !important;
            margin-top: 0 !important;
            text-align: center !important;
            max-width: none;
        }

        .sli-input {
            font-size: 14px !important;
            height: 40px !important;
            min-height: 30px;
            padding: 10px 15px !important;
        }

        .sli-input[type="checkbox"] {
            height: 16px !important;
            font-size:: 16px !important;
        }

        .sli-donorPaysFees,
        .sli-selectRecurring,
        .sli-recurringFrequency {
            margin-left: auto !important;
            margin-right: auto !important;
            line-height: 1.3 !important;
        }

        .sli-oneTimeAmountLabel,
        .sli-recurringAmountLabel,
         {
            height: 35px !important;
            margin: 0 0 0 10px !important
        }

        .sli-style-button label {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        .sli-oneTimeDonationAmounts {
            &.sli-style-button {
                display: flex;
                align-items: center;
                justify-content: center;
                /*height: 40px !important;*/
                /*margin: 5px !important;*/

                label {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }

        .sli-oneTimeDonation,
        .sli-recurringDonation {
            padding: 0 !important;

            h4, ul {
                width: 100%;
                margin-left: auto !important;
                margin-right: auto !important;
                margin-bottom: 0 !important;
                text-align: center !important;
                max-width: none;
            }

            h4 {
                margin-top: 0 !important;
            }
        }

        .sli-oneTimeDonationAmounts,
        .sli-recurringDonationAmounts {
            li {
                margin: 0 10px !important;
                padding-left: 0!important;
                width: auto !important;
                display: flex !important;
            }
        }

        #sli-oneTimeDonationAmounts,
        #sli-recurringDonationAmounts {
            li {
                margin: 0 10px !important;
                padding-left: 0!important;
                width: auto !important;
                display: inline-block !important;
            }
        }

        .sli-oneTimeDonationError,
        .sli-recurringDonationError {
            width: 100%;
            margin-left: auto !important;
            margin-right: auto !important;
            margin-bottom: 0 !important;
            text-align: center;
        }

        .sli-recurringFrequency {
            font-style: italic;
            padding: 15px 0 0 25px !important;
        }

        .sli-donorPaysFees,
        .sli-selectRecurring {
            input {
                margin-right: 10px !important;
                margin-bottom: 8px !important;
            }
        }

        .sli-donorPaysFees {
            text-align: center;
            margin: 0 auto 20px auto !important;
            order: 3 !important;
            width: 470px;
        }

        .sli-row {
            padding: 0 !important;
        }

        .sli-donationsummary-dedication {
            padding: 0 !important;

            input {
                margin-bottom: 0 !important;
            }
        }

        .sli-donation-amountblock ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center !important;
            list-style: none;
        }

        .sli-donationsummary-dedication ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start !important;
            list-style: none;

            li {
                display: flex !important;
                margin: 10px 30px 10px 0 !important;
                padding: 0 !important;

                input {
                    margin-right: 8px !important;
                }
            }
        }

        .sli-donation-paymentblock {
            /*border-top: 1px solid #ddd;*/
            border-bottom: 1px solid #ddd;
            margin: 5px 0 30px 0 !important;
            padding: 15px 0 30px 0 !important;

            h4 {
                text-align: center;
            }

            .sli-large-8.sli-small-12 {
                width: 100% !important;
            }

            .sli-ccExpCcvInput {
                width: 100% !important;
            }

            .sli-large-8 {
                width: 66.67% !important;
            }

            .sli-large-8 + .sli-large-4.sli-end {
                float: right !important;
                width: 33% !important;
            }

            .sli-large-4:first-child {
                float: left !important;
                width: 50% !important;
            }

            .sli-large-4 + .sli-large-4.sli-end {
                float: right !important;
                width: 50% !important;
            }
        }

        label {
            font-size: 15px !important;
            margin-top: 2px !important;
            margin-bottom: 6px !important;
        }

        .sli-button {
            background: $bg-blue !important;
            color: white;
            font-family: 'museo', Helvetica, Arial, sans-serif;
            font-size: 24px !important;
            margin: 35px 0 10px 0 !important;
            padding: 15px 40px !important;
            transition: background .3s ease !important;
            text-decoration: none;

            &:hover {
                background: darken($bg-blue, 5%) !important;
                color: white;
            }
        }

        .sli-field-error {
            padding: 5px 0 0 0 !important;
            line-height: 1.3 !important;
        }

        .sli-cards {
            align-items: center;
            display: flex !important;
            justify-content: center;
            margin-left: 50% !important;
            transform: translateX(-50%) !important;
            width: 250px !important;
        }

        input.sli-customAmount {
            height: 30px !important;
            display: inline-block;
            margin-left: 5px !important;
            max-width: 75px !important;
            min-width: 75px !important;
            padding: 8px !important;
            font-size: 15px !important;
        }

        .sli-customAmountsli-label {
            margin: 0 0 0 10px !important;
        }

        .sli-donationsummary-notify {
            padding: 0 !important;
        }

        .sli-form input[type="text"][disabled] {
            background: #f6f6f6 !important;
        }

        .sli-checkout-summary {
            margin-top: 20px !important;
        }

        .sli-checkout-summary-table thead th,
        .sli-checkout-summary-table thead td {
            border-radius: 0 !important;
        }

        .sli-socialShare {
            text-align: center;

            h1, h2, h3, h4, h5, h6, p {
                font-family: 'museo-sans', Helvetica, Arial, sans-serif !important;
                color: $text-body-dark !important;
            }

            h3 {
                font-size: 60px;
                font-weight: 300;
                font-family: 'museo', Helvetica, Arial, sans-serif !important;
                color: $text-blue !important;
            }

            p {
                font-size: 16px !important;
            }

            .sli-social-sharing-item {
                float: none !important;
                display: inline-block !important;
                margin-right: 5px !important;
                width: 62px !important;
                margin: 0 5px !important;
                height: 22px !important;
                overflow: hidden !important;

                &:first-of-type {
                    left: 2px;
                    position: relative;
                    top: -2px;
                }
            }
        }
    }

    .button__return-to-site {
        text-align: center;
        margin-top: 20px;

        a, a:hover {
            text-decoration: none !important;
        }
    }
}

/**
 * Move donation checkboxes down
 */
.sli-donationAmounts,
.sli-recurringDonation,
.sli-form .sli-columns {
    display: flex;
    flex-direction: column;

    .sli-recurringDonation {
        * {
            order: 1;
        }
    }

    .sli-row {
        order: 4;
    }

    .sli-element {
        order: 1;
    }

    .sli-row:nth-child(1) {
        order: 3;
    }

    .sli-element + .sli-row {
        order: 2;
    }
}

.sli-recurringFrequency {
    margin-bottom: 20px !important;
    margin-top: -10px !important;
    order: 2 !important;
    padding: 0 !important;
    text-align: center;
    width: 100%;
}

.sli-selectRecurring {
    order: 2;
    text-align: center;
    margin-bottom: 20px !important;
    margin-top: 0 !important;
}

.sli-oneTimeDonation {
    order: 1;
}

.sli-form input[type="radio"] + .sli-label {
    margin-right: .5em !important;
}

/**
 * Fade in/out
 */

.fade-in-out {
    transition: opacity .3s ease-in-out;
}

.fade-in-out--in {
    opacity: 1.0;
    transition: opacity .3s ease-in-out;
}

.fade-in-out--out {
    opacity: 0;
    transition: opacity .3s ease-in-out;
}

