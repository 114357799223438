.search {
    &__container {
        align-items: center;
        display: flex;
        height: 45px;
        margin: 15px 0 30px 0;
        position: relative;
        width: 100%;

        @include breakpoint(xs, true) {
            margin: 0 0 70px 0;
            flex-direction: column;

            .search__box {
                border-left: 1px solid $color-gray-light;
                border-top: none;
            }

            .dropdown-select {
                width: 100%;

                &__button {
                    width: 100%;
                }
            }
        }
    }

    &__box-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }

    &__filter-button {
        -webkit-appearance: none;
        align-items: center;
        background: transparent;
        border-radius: 0;
        border: 1px solid $color-gray-light;
        color: $color-gray;
        display: flex;
        flex: 0 0 auto;
        font-size: .85em;
        font-weight: 400;
        height: 100%;
        justify-content: center;
        letter-spacing: .2em;
        line-height: 1.25;
        margin: 0;
        min-width: 230px;
        outline: 0 !important;
        padding: 0 2.8em;
        text-transform: uppercase;
        transition: all .2s ease-in-out;
        user-select: none;
        white-space: nowrap;

        .icon {
            font-size: 18px;
            color: $color-gray;
            margin-right: 10px;
        }

        &:active, &:focus, &:hover {
            background: $bg-maroon;
            border-color: $bg-maroon;
            color: white;
            cursor: pointer;
            outline: 0 !important;
            text-decoration: none;

            .icon {
                color: white;
            }
        }

        &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
        }
    }

    &__box {
        border-bottom: 1px solid $color-gray-light;
        border-left: none;
        border-right: 1px solid $color-gray-light;
        border-top: 1px solid $color-gray-light;
        color: $text-blue;
        flex: 1 1 auto;
        height: 100%;
        width: 100%;
        outline: 0 !important;
        padding: 5px 50px 5px 15px;
        border-radius: 0 !important;

        &--has-value {
            font-size: 18px;
            font-weight: 400;
        }

        &:active, &:focus {
            + .search__icon {
                background: $bg-blue;
                color: white;

                &:hover {
                    background: $bg-maroon;
                    color: white;
                }
            }
        }
    }

    &__icon {
        align-items: center;
        background: transparent;
        color: $color-gray;
        cursor: pointer;
        display: flex;
        font-size: 24px;
        height: 45px;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
        transition: all .2s ease-in-out;
        width: 45px;

        &:hover {
            background: $bg-maroon;
            color: white;
        }
    }

    &__clear-icon {
        align-items: center;
        background: transparent;
        color: $color-gray;
        cursor: pointer;
        display: flex;
        font-size: 38px;
        height: 45px;
        justify-content: center;
        position: absolute;
        right: 45px;
        top: 0;
        transition: all .2s ease-in-out;
        width: 45px;

        &:hover {
            color: $bg-maroon;
        }
    }
}

.search-global {
    &__container {
        align-items: center;
        display: flex;
        height: 28px;
        margin-left: 15px;
        position: relative;
        width: 180px;

        .search__container {
            height: 28px;
            margin: 0;

            input {
                background: transparent;
                width: 180px;

                &:active, &:focus, &:hover {
                    background: white;
                }
            }
        }

        .search__icon {
            font-size: 19px;
            height: 28px;
            width: 28px;
        }
    }

    .btn-link {
        cursor: pointer;
    }

    .search__box {
        border-left: 1px solid $color-gray-light;
        border-top: 1px solid $color-gray-light;
        padding: 4px 30px 4px 5px;

        &--has-value {
            font-size: 14px;
            font-weight: 400;
        }
    }
}
