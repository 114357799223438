$text-body: #6D6E70;
$text-body-medium: #4c4d4d;
$text-body-dark: #22292a;
$text-blue: #0A7FA3;
$text-yellow: #d38012;

$bg-blue: #0A7FA3;
$bg-tan: #eef0e9;
$bg-tan-light: #f8f8f6;
$bg-tan-dark: #e1e4dd;
$bg-blue: #0888b9;
$bg-maroon: #960000;
$bg-yellow: #f29e0d;

$color-danger: hsv(3, 64%, 73%);
$color-danger-light: hsv(3, 48%, 98%);

$color-warning: #ffeecc;
$color-warning-dark: #c38200;
$color-warning-medium: desaturate(lighten($color-warning-dark, 35%), 6%);

$color-white: #fff;
$color-black: #202020;
$color-gray-dark: #3c3c3c;
$color-gray-lightest: lighten($color-gray-dark, 70%);
$color-gray-lighter: lighten($color-gray-dark, 60%);
$color-gray-light: lighten($color-gray-dark, 50%);
$color-gray: lighten($color-gray-dark, 35%);
$color-gray-medium: lighten($color-gray-dark, 25%);

$color-blue-gray: #dadce4;

$color-teal-light-translucent: rgba(235, 245, 245, .95);
$color-blue-light-translucent: rgba(216, 232, 255, .97);

$color-input-border-focus: #66afe9;
