/*
    @param {string} bp Breakpoint value. One of `xs, sm, md, lg`.
    @param {boolean} isMaxWidth By default, the media queries are mobile first,
        so they use `min-width: __px`. By passing `true`, the mixin will subtract
        one pixel from the breakpoint value and make it `max-width: __px`.
    @param {boolean} isScreenOnly Whether to hide this media query from print styles.
*/

$breakpoint-widths: (
  xxs: 320px,
  xs: 570px,
  sm: 768px,
  md: 1024px,
  lg: 1400px,
  xl: 1600px
);

@mixin breakpoint($bp, $isMaxWidth: false, $isScreenOnly: true) {
  $media-query: get-breakpoint-query($bp, $isMaxWidth);

  @if $isScreenOnly {
    $media-query: "screen and #{$media-query}";
  }

  @media #{$media-query} {
    @content;
  }
}

@function get-breakpoint-query($bp, $isMaxWidth: false) {
  $breakpoint: map-get($breakpoint-widths, $bp);
  $media: if($isMaxWidth, 'max-width', 'min-width');

  @if $isMaxWidth {
    $breakpoint: $breakpoint - 1px;
  }

  @if map-has-key($breakpoint-widths, $bp) {
    @return "(#{$media}: #{$breakpoint})";
  } @else {
    @warn "#{$bp} not recognized. Valid breakpoints: #{map-keys($breakpoint-widths)}";
    @return "screen";
  }
}

@mixin breakpoints($min, $max) {
  $start: map-get($breakpoint-widths, $min);
  $end: map-get($breakpoint-widths, $max);

  @if map-has-key($breakpoint-widths, $min) and map-has-key($breakpoint-widths, $max) {
    $end: $end - 1px;
    @if $start > $end {
      @warn "Unreachable media query: #{$start} <--> #{$end}";
      @content;
    } @else {

      $media-query-min: "(min-width: #{$start})";
      $media-query-max: "(max-width: #{$end})";

      /* There should be no lower bound when the extra small breakpoint is used.*/
      /* e.g. `@include breakpoints(xs, sm)` should be from 0 <--> 760, not 328 <--> 760.*/
      @if $min == xs {
        @media screen and #{$media-query-max} {
          @content;
        }
      } @else {
        @media screen and #{$media-query-min} and #{$media-query-max} {
          @content;
        }
      }
    }
  } @else {
    @warn "#{$min} or #{$max} breakpoint not recognized. Valid breakpoints: #{map-keys($breakpoint-widths)}";
    @content;
  }
}
