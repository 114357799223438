.pagination-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-top: -20px;
    min-height: 48px;

    @include breakpoint(xs, true) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 0;
    }

    p {
        color: $bg-maroon;
        font-family: 'museo', Helvetica, Arial, sans-serif;
        font-size: 18px;
        font-weight: 400;
        margin: 0;
    }
}

.pagination {
    border-radius: 4px;
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
    padding-left: 0;

    a {
        align-items: center;
        border-bottom: 1px solid $color-gray-lighter;
        border-right: 1px solid $color-gray-lighter;
        border-top: 1px solid $color-gray-lighter;
        cursor: pointer;
        display: flex;
        flex: 0 0 auto;
        font-size: 13px;
        font-weight: 400;
        height: 28px;
        justify-content: center;
        min-width: 28px;
        overflow: hidden;
        padding-top: 2px;
        text-decoration: none;

        &:first-of-type {
            border-left: 1px solid $color-gray-lighter;
        }

        &.current,
        &.current:hover,
        &.current:focus {
            background: $bg-maroon;
            color: white;
            border-bottom-color: $bg-maroon;
            border-right-color: $bg-maroon;
            border-top-color: $bg-maroon;

            &:first-of-type {
                border-left-color: $bg-maroon;
            }
        }

        &:hover {
            background: $bg-tan;
            color: $bg-maroon;
        }
    }
}
