$tile-hover-speed: .2s;

.tile__square {
    width: 33.33vw;
}

.tile__container--grid {
    .tile {
        margin: 0 15px 20px;
        height: 95%;
    }

    .tile:not(:first-child) {
        @include breakpoint(xs) {
            &:after {
                content: '';
                height: 100%;
                background: none;
                width: 0;
                position: absolute;
                top: 0;
            }
        }
    }
}

.tile {
    background: lighten($bg-tan, 3%);
    margin: 0 0 25px 0;
    flex: 1;
    padding-bottom: 2px;
    position: relative;
    transition: all $tile-hover-speed;

    @include breakpoint(xs) {
        margin: 0 15px;
    }

    &:hover {
        .tile__body {
            p {
                color: white;
            }
        }

        .tile__heading {
            color: white;
        }
    }

    &:not(:first-child) {
        @include breakpoint(xs) {
            &:after {
                content: '';
                height: 100%;
                background: $color-gray-lighter;
                width: 1px;
                position: absolute;
                top: 0;
                transform: translateX(-15px);
            }
        }
    }

    a {
        text-decoration: none;
    }

    &__body {
        p {
            margin: 0;
            color: #60696c;
            font-family: 'museo', Helvetica, Arial, sans-serif;
            font-size: 15px;
            font-weight: 300;
            letter-spacing: .01em;
            line-height: 1.4;
            transition: color $tile-hover-speed;

            @include breakpoint(xs, true) {
                font-size: 13px;
            }
        }
    }

    &__border-top {
        background: transparent;
        height: 6px;
        position: absolute;
        top: 0;
        transition: background $tile-hover-speed;
        width: 100%;
        z-index: 10;
    }

    &__container {
        display: flex;
        margin: 0;
        position: relative;
        flex-direction: column;
        top: 0;

        @include breakpoint(xs) {
            margin: 0 -15px;
            flex-direction: row;
        }

        &--wrap {
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__heading {
        font-family: 'museo', Helvetica, Arial, sans-serif;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: .01em;
        line-height: 1.1;
        margin: 0 0 15px;
        transition: color $tile-hover-speed;

        &--no-description {
            margin-bottom: 0;
        }

        @include breakpoint(xs, true) {
            font-size: 18px;
            line-height: 1.2;
            margin-bottom: 8px;
        }
    }

    &__image {
        background: $bg-tan;
        height: 150px;
        overflow: hidden;
        width: 100%;

        @include breakpoint(md) {
            height: 180px;
        }

        img {
            display: block;
            min-height: 102%;
            min-width: 102%;
            position: relative;
            top: 50%;
            transform: translate(-1%,-51%);
            width: 100%;
        }
    }

    &__overlay {
        background: transparent;
        box-shadow: inset 0 4px 20px -5px rgba(0, 0, 0, .15);
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 100;
        pointer-events: none;
    }

    &__text {
        padding: 15px 20px 20px;
    }

    &--yellow {
        .tile__heading {
            color: $text-yellow;
        }

        &:hover {
            background: $bg-yellow;

            .tile__border-top {
                background: $bg-yellow;
            }
        }
    }

    &--blue {
        .tile__heading {
            color: $bg-blue;
        }

        &:hover {
            background: $bg-blue;

            .tile__border-top {
                background: $bg-blue;
            }
        }
    }

    &--maroon {
        .tile__heading {
            color: $bg-maroon;
        }

        &:hover {
            background: $bg-maroon;

            .tile__border-top {
                background: $bg-maroon;
            }
        }
    }

    &--3-col {
        flex: 0 0 100%;
        margin-bottom: 10px;
    }

    &--2-col {
        flex: 0 0 66.6667%;
        margin-bottom: 10px;
    }

    &--1-col {
        flex: 0 0 33.3333%;
        margin-bottom: 10px;
    }

    &--grid {
        flex: 0 0 100%;
        max-width: 240px;
        margin: auto;

        .tile__image {
            height: 180px;
        }

        @include breakpoint(xs) {
            flex: 0 0 50%;
            margin: 0;

            .tile__image {
                height: 180px;
            }
        }

        @include breakpoint(sm) {
            flex: 0 0 33.333%;
        }

        @include breakpoint(md) {
            flex: 0 0 25%;

            .tile__image {
                height: 200px;
            }
        }

        @include breakpoint(lg) {
            flex: 0 0 20%;
        }
    }
}

.tile__container--wrap {
    .tile {
        min-height: 90%;
    }
}

.tile__image {
    &.tile__image--auto-height {
        height: auto !important;
        width: 100%;

        img {
            display: block;
            min-height: auto;
            min-width: auto;
            position: relative;
            top: 0;
            transform: none;
            width: 100%;
        }
    }
}
