.dropdown-select {
    position: relative;

    &__menu {
        background: white;
        border-bottom: 1px solid $color-gray-light;
        border-left: 1px solid $color-gray-light;
        border-right: 1px solid $color-gray-light;
        border-top: none;
        box-shadow: 2px 2px 11px -5px black;
        cursor: pointer;
        position: absolute;
        width: 100%;
        z-index: 100;
    }

    &__item {
        font-weight: 400;
        line-height: 1.3;
        padding: 10px 16px;
        transition: all .15s ease-in-out;

        &:hover {
            background: $bg-tan;
            color: $text-blue;
            cursor: pointer;
            text-decoration: none;
        }
    }
}
