$font-body: 'Muli', sans-serif;

html, body {
    -webkit-font-smoothing: antialiased;
    font-family: 'museo-sans', Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
}

[v-cloak] {
    display: none;
}

.align-center {
    text-align: center !important;
}

.align-left {
    text-align: left !important;
}

.align-right {
    text-align: right !important;
}

.footnote {
    border-top: 1px solid #d8d8d8;
    margin-top: 38px;
    padding-top: 35px;

    p {
        color: #60696c;
        font-family: 'museo', Helvetica, Arial, sans-serif;
        font-size: 15px;
        font-weight: 300;
        letter-spacing: .01em;
    }
}

h1 {
    color: #505d62;
    font-family: 'museo', Helvetica, Arial, sans-serif;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: -.010em;
    line-height: 1.1;
    margin: 15px 0 35px 0;
    text-align: left;

    @include breakpoint(xs, true) {
        font-size: 28px;
        margin: 5px 0 25px 0;
    }

    a {
        font-weight: 400;

        &:hover {
            text-decoration: none;
            font-weight: 400;
            color: $text-yellow;
        }
    }

    &.page-title {
        color: #0A7FA3 !important;
        text-align: center;
        font-size: 46px;

        @include breakpoint(xs, true) {
            font-size: 38px;
            margin-top: 30px;
        }

        &--left {
            text-align: left;
        }
    }
}

.smaller-headings {
    h1.page-title {
        font-size: 36px;
        margin: 40px 0;

        @include breakpoint(xs, true) {
            font-size: 28px;
            margin-top: 16px;
        }
    }

    h2 {
        font-size: 28px;
        margin: 36px 0;

        @include breakpoint(xs, true) {
            font-size: 18px;
            margin-top: 14px;
        }
    }

    h3 {
        font-size: 22px;
        margin: 34px 0;

        &.tile__heading {
            margin: 0 0 15px;
        }

        @include breakpoint(xs, true) {
            font-size: 15px;
            margin-top: 10px;
        }
    }
}

h2 {
    color: #505d62;
    font-size: 32px;
    line-height: 1.25;
    margin: 35px 0 15px 0;

    a {
        font-weight: 500;
    }

    + h2 {
        margin-top: 20px;
    }

    @include breakpoint(xs, true) {
        font-size: 24px;
        margin-top: 12px;
    }
}

h3 {
    color: #505d62;
    font-size: 24px;
    line-height: 1.25;
    margin: 35px 0 15px 0;

    a {
        font-weight: 400;
    }

    + h3 {
        margin-top: 20px;
    }

    @include breakpoint(xs, true) {
        font-size: 18px;
        margin-top: 10px;
    }
}

ul, ol {
    padding: 0 1.8em .5em;

    li {
        color: $text-body-dark;
        font-weight: 300;
        font-size: 16px;
        padding: 5px 0;

        @include breakpoint(xs, true) {
            font-size: 12px;
        }
    }
}

p {
    color: $text-body-dark;
    font-family: $font-body;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.55;
    margin-bottom: 25px;

    + ol, + ul {
        margin-top: -10px;
    }

    @include breakpoint(xs, true) {
        font-size: 14px;
        line-height: 1.65;
        margin-bottom: 20px;
    }
}

a {
    color: $text-blue;
    text-decoration: none;
    font-weight: 600;

    &:hover {
        color: $text-yellow;
    }
}

hr {
    border-top-color: #ddd;
    margin-bottom: 0;
    margin-top: 0;
}

.container {
    display: flex;
    flex-direction: column;
    margin: 0;
    max-width: none;
    overflow: hidden;
    padding: 0;
    width: auto;

    @include breakpoint(xl) {
        margin-left: auto;
        margin-right: auto;
        width: 1600px !important;
    }
}

.page {
    color: $text-body;
    font-weight: 300;
}

.page__background {
    background: $bg-tan;
    margin-top: 60px;

    @media (min-width: 740px) {
        margin-top: 90px;
    }

    @media (min-width: 850px) {
        margin-top: 120px;
    }
}

.page__top-bar {
    background: $bg-blue;
    color: #fff;
    font-family: 'museo', Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 300;
    text-align: center;

    h1 {
        line-height: 1.15;
        margin-bottom: 0;
        margin-top: 0;
        padding: 22px;
    }
}

.page__intro {
    h2 {
        color: $text-body;
        font-family: 'museo', Helvetica, Arial, sans-serif;
        font-size: 19px;
        font-weight: 300;
        line-height: 1.5;
        margin: 0;
        text-align: left;
    }
}

/*
 * Vue transition, fade in & out and expand down a bit
 */
.search-global-enter-active, .search-global-leave-active {
    transition: all .2s;
    transform: translateY(0);
}

.search-global-enter, .search-global-leave-active {
    opacity: 0;
    transform: translateY(-10px);
}

/* Images */
.image-key {
    width: 50%;
    float: right;
    margin: 0 0 10px 20px;
}

.image-block {
    width: 50%;
    margin: 30px auto;
}

.bg-white {
    background: white;

    .text-accent-color {
        color: $color-gray-medium;
    }
}

.bg-tan {
    background: $bg-tan;

    .text-accent-color {
        color: $color-gray-medium;
    }
}

.bg-tanLight {
    background: $bg-tan-light;

    .text-accent-color {
        color: $color-gray-medium;
    }
}

.bg-tanDark {
    background: $bg-tan-dark;

    .text-accent-color {
        color: $color-gray-medium;
    }
}

.bg-blue {
    background: $bg-blue;

    .text-accent-color {
        color: desaturate(lighten($bg-blue, 25%), 30%);
    }
}

.bg-yellow {
    background: $bg-yellow;

    .text-accent-color {
        color: lighten($bg-yellow, 30%);
    }
}

.bg-maroon {
    background: $bg-maroon;

    .text-accent-color {
        color: desaturate(lighten($bg-maroon, 25%), 30%);
    }
}

.header {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

    @include breakpoint(md) {
        position: fixed;
    }

    &__container {
        align-items: flex-start;
        display: flex;
        height: 60px;
        justify-content: space-between;
        margin: 0;
        max-width: none;
        padding: 0 20px;
        width: 100%;

        @media (min-width: 740px) {
            height: 90px;
            padding: 0 20px 0 30px;
        }

        @media (min-width: 850px) {
            height: 120px;
        }

        @include breakpoint(lg) {
            padding: 0 30px;
        }

        @include breakpoint(xl) {
            margin-left: auto;
            margin-right: auto;
            padding: 0;
            width: 1600px !important;
        }
    }

    &__left {
        height: 100%;
        display: flex;

        a {
            display: flex;
            align-items: center;

            .logo-newdream {
                width: 130px;

                @media (min-width: 740px) {
                    margin-bottom: 4px;
                }

                @media (min-width: 850px) {
                    width: 260px;
                }
            }

        }
    }

    &__middle {
        align-items: flex-end;
        display: none;
        flex-direction: column;
        flex: 1 1 auto;
        height: 100%;
        justify-content: space-between;

        @media (min-width: 740px) {
            display: flex;
        }
    }

    &__mobile {
        display: flex;
        align-items: flex-end;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        @media (min-width: 740px) {
            display: none;
        }

        .search-global {
            padding: 20px 20px 5px;

            .search-global__container {
                height: 38px;
                width: 100%;
                padding: 0;
                margin: 0;

                .search__container {
                    height: 38px;

                    input {
                        width: 100%;
                        font-size: 15px;
                    }

                    .search__icon {
                        font-size: 26px;
                        height: 38px;
                        width: 38px;
                    }
                }
            }
        }

        .search__box {
            padding: 4px 30px 4px 8px !important;

            &:active, &:focus {
                background: white;
            }
        }
    }

    &__right {
        height: 100%;
        display: none;

        @include breakpoint(md) {
            display: flex;
        }

        a {
            display: flex;
            align-items: center;

            .logo-sokind {
                margin: 0 0 2px 20px;
                width: 120px;

                @include breakpoint(lg) {
                    margin: 0 0 6px 30px;
                }
            }
        }
    }

    &__translucent {
        background: rgba(255, 255, 255, .9);
        width: 100%;
    }

    &__shadow {
        background: transparent;
        box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, .3);
        height: 20px;
        width: 100%;
    }

    &__menu-top {
        align-items: center;
        display: flex;
        margin-top: 16px;

        p {
            font-size: 14px;
            margin: 0;
        }
    }

    .icon-social {
        font-size: 16px;
        margin-right: 13px;
        color: $color-gray-medium;

        &:last-of-type {
            margin-right: 0;
        }

        &:hover {
            color: $text-yellow;
        }
    }
}

.form__bottom {
    font-weight: 400;
    letter-spacing: -.01em;
    text-align: center;
}

.footer {
    background: white;
    padding: 20px 0 30px 0;
    border-top: 6px solid $bg-blue;

    @include breakpoint(xs) {
        padding: 40px 0 50px 0;
    }

    &__content {
        align-items: center;
        color: $text-blue;
        display: flex;
        font-weight: 400;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;

        p {
            font-size: 14px;
            margin: 2px 8px;
            line-height: 1.6;

            @include breakpoint(xs) {
                margin: 0;
            }
        }
    }

    .icon-social {
        font-size: 16px;
        margin-right: 15px;
        color: $color-gray-medium;

        &:hover {
            color: $text-yellow;
        }

        @include breakpoint(xs, true) {
            margin: 0 8px;

            &.m-r-0 {
                margin-right: 15px !important;
            }
        }
    }

    .rights {
        align-items: center;
        color: $text-blue;
        display: flex;
        font-size: 13px;
        font-weight: 400;
        justify-content: center;
        text-align: center;
    }

    .rights a.tw, .rights a.fb, .rights a.vm, .rights a.in {
        display: inline-block;
        height: 22px;
        width: 30px;
        overflow: hidden;
        background-image: url('/images/logos/newdream_icons_social_tiny.png');
        vertical-align: middle
    }
    .rights a.tw, .rights a.fb, .rights a.vm {
        border-right:1px solid #dddddd
    }

    .rights a.tw {
        background-position: 0 0
    }

    .rights a.fb {
        background-position: -29px 0
    }

    .rights a.vm {
        background-position: -63px 0
    }

    .rights a.in {
        background-position: -94px 0;
        margin-right: 11px
    }

    .rights a.tw:hover {
        background-position: 0 -22px
    }

    .rights a.fb:hover {
        background-position: -29px -22px
    }

    .rights a.vm:hover {
        background-position: -63px -22px
    }

    .rights a.in:hover {
        background-position: -94px -22px
    }
}

.error-page {
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
