$spacers: (
    0: 0,
    1: 0.25rem,
    2: 0.5rem,
    3: 0.75rem,
    4: 1rem,
    5: 1.5rem,
    6: 2rem,
    7: 3rem,
    8: 4rem,
    9: 5rem
);

$breakpoints: (
    '320px': '',
    '570px': '-xs',
    '768px': '-sm',
    '1024px': '-md',
    '1392px': '-lg',
);

/*
 * --------------------
 * Margin & padding utilities
 * --------------------
 */
@mixin margin-breakpoints($breakpoint, $number, $value) {
    .m#{$breakpoint}-t-#{$number} { margin-top: $value !important; }
    .m#{$breakpoint}-b-#{$number} { margin-bottom: $value !important; }
    .m#{$breakpoint}-l-#{$number} { margin-left: $value !important; }
    .m#{$breakpoint}-r-#{$number} { margin-right: $value !important; }
    .m#{$breakpoint}-x-#{$number} { margin-left: $value !important; margin-right: $value !important; }
    .m#{$breakpoint}-y-#{$number} { margin-top: $value !important; margin-bottom: $value !important; }
    .m#{$breakpoint}-#{$number} { margin: $value !important; }
}

@mixin padding-breakpoints($breakpoint, $number, $value) {
    .p#{$breakpoint}-t-#{$number} { padding-top: $value !important; }
    .p#{$breakpoint}-b-#{$number} { padding-bottom: $value !important; }
    .p#{$breakpoint}-l-#{$number} { padding-left: $value !important; }
    .p#{$breakpoint}-r-#{$number} { padding-right: $value !important; }
    .p#{$breakpoint}-x-#{$number} { padding-left: $value !important; padding-right: $value !important; }
    .p#{$breakpoint}-y-#{$number} { padding-top: $value !important; padding-bottom: $value !important; }
    .p#{$breakpoint}-#{$number} { padding: $value !important; }
}

@each $breakpoint, $breakpoint-short in $breakpoints {
    @if $breakpoint-short != '' {
        @media (min-width: $breakpoint) {
            @each $number, $value in $spacers {
                @include margin-breakpoints($breakpoint-short, $number, $value);
                @include padding-breakpoints($breakpoint-short, $number, $value);
            }
        }
    }
    @else {
        @each $number, $value in $spacers {
            @include margin-breakpoints($breakpoint-short, $number, $value);
            @include padding-breakpoints($breakpoint-short, $number, $value);
        }
    }
}
